<template>
  <div>
    <!-- Titulo -->
    <v-row dense>
      <v-col cols="12">
        <v-card class="pa-5">
          <v-row dense>
            <v-col
              cols="12"
              class="d-flex flex-row justify-md-start justify-center"
            >
              <span class="d-flex flex-row justify-start align-center">
                <h2>{{ $t("Parcel history") }}</h2>
                <v-tooltip top :max-width="300">
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      medium
                      v-on="on"
                      class="ml-6 color-gray-text"
                    >
                      {{ icons.mdiInformationOutline }}
                    </v-icon>
                  </template>
                  <span>
                    <!-- <ul> -->
                    <!-- <li> -->
                    {{ $t("tooltipHistoryParcel") }}
                    <!-- </li> -->
                    <!-- </ul> -->
                  </span>
                </v-tooltip>
              </span>
            </v-col>
          </v-row>

          <!-- Filtros -->
          <v-row dense class="mt-4">
            <v-col cols="12" md="2">
              <v-select
                :label="$t('Search by...')"
                outlined
                dense
                :items="[
                  `${$t('user_first_name')}`,
                  `${$t('provider_name')}`,
                  `${$t('unique_id')}`,
                ]"
                v-model="table.searchItem"
                clearable
                :disabled="table.tableLoading"
              ></v-select
            ></v-col>
            <v-col cols="12" md="2">
              <v-text-field
                :label="$t('Search')"
                :prepend-inner-icon="icons.mdiMagnify"
                outlined
                dense
                v-model="table.search"
                :disabled="!table.searchItem"
                clearable
                @keyup.esc="table.search = ''"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-select
                label="Status"
                outlined
                dense
                :items="[
                  `${$t('Completed')}`,
                  `${$t('Cancelled')}`,
                  `${$t('All')}`,
                ]"
                v-model="table.status"
                :disabled="table.tableLoading"
              >
                <template v-slot:selection="{ item }">
                  <v-chip
                    v-if="item === $t('Cancelled')"
                    class="v-chip-light-bg error--text"
                    color="accent"
                  >
                    {{ $t(item) }}</v-chip
                  >
                  <v-chip
                    v-else-if="item === $t('Completed')"
                    class="v-chip-light-bg success--text"
                    color="success"
                  >
                    {{ $t(item) }}</v-chip
                  >
                  <v-chip v-else>
                    {{ $t(item) }}
                  </v-chip>
                </template>

                <template v-slot:item="{ active, item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title class="cursor-hover">
                        <v-chip
                          v-if="item === $t('Cancelled')"
                          class="v-chip-light-bg error--text cursor-hover"
                          color="accent"
                        >
                          {{ $t(item) }}</v-chip
                        >
                        <v-chip
                          v-else-if="item === $t('Completed')"
                          class="v-chip-light-bg success--text cursor-hover"
                          color="success"
                        >
                          {{ $t(item) }}</v-chip
                        >
                        <v-chip v-else class="cursor-hover">
                          {{ $t(item) }}
                        </v-chip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="2">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    :label="$t('Date range')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                    clearable
                    :append-icon="icons.mdiCalendar"
                    :disabled="table.tableLoading"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  range
                  no-title
                  scrollable
                  :max="computedMaxDate"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    {{ $t("Cancel") }}
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu></v-col
            >
            <!-- {{date.length < 1}} -->
            <v-col cols="12" md="2">
              <v-btn
                block
                color="primary"
                @click="getHistoryFilter()"
                :disabled="table.tableLoading"
              >
                {{ $t("Apply") }}</v-btn
              ></v-col
            >
            <v-col
              cols="12"
              md="2"
              :class="!$vuetify.breakpoint.mdAndUp && 'mt-5'"
            >
              <v-btn
                block
                outlined
                color="primary"
                @click="handleExport()"
                :disabled="table.tableLoading"
              >
                <v-icon> {{ icons.mdiDownload }}</v-icon>
                <span class="ml-2">{{ $t("Export") }}</span>
              </v-btn></v-col
            >
          </v-row>
          <v-row
            dense
            v-if="is_corporate_api_partner && is_allowed_corporate_shipment_api"
          >
            <v-col
              cols="6"
              class="mt-lg-n3 d-flex flex-row align-baseline mb-lg-n4"
            >
              <v-switch
                v-model="onlyAPIParcel"
                flat
                dense
                class="ma-0 pa-0"
                :disabled="table.tableLoading"
              >
                <template v-slot:label>
                  <span v-if="onlyAPIParcel">
                    {{
                      $isSpanish
                        ? "Se muestran sólo envíos creados por API"
                        : "Only Parcels created by API are shown"
                    }}
                  </span>
                  <span v-else>
                    {{
                      $isSpanish
                        ? "Se muestran envíos creados por panel corporativo y API"
                        : "Parcels created by corporate panel and API are shown"
                    }}
                  </span>
                </template>
              </v-switch>
            </v-col>
          </v-row>

          <!-- tabla -->
          <v-row dense class="mt-4">
            <v-col cols="12">
              <DataTable
                :loading="table.tableLoading"
                :headers="table.headers"
                :items="computedTable"
                :page="table.currentPage"
                v-if="!new_"
              />
              <!-- v-if="!new_" -->
            </v-col>
          </v-row>
          <v-row
            dense
            v-if="!new_"
            class="d-flex flex-row justify-space-between align-center"
          >
            <v-col
              cols="12"
              md="4"
              class="d-flex flex-row justify-end align-center pt-8"
            >
              <v-select
                :label="$t('Results per page')"
                :items="[5, 10, 20, 30]"
                v-model="table.limit"
                :disabled="table.tableLoading"
                dense
                outlined
              >
              </v-select>
              <v-autocomplete
                :class="!$vuetify.mdAndUp && 'ml-2'"
                :label="$t('Go to page')"
                :items="Array.from(Array(table.pages).keys()).slice(1)"
                v-model="table.currentPage"
                :disabled="table.tableLoading"
                dense
                outlined
                @input="table.currentPage && handlePageChange()"
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="d-flex flex-row justify-md-end justify-center align-start"
            >
              <!-- <code>currentPage: {{ table.currentPage }}</code>
              <code>Pages: {{ table.pages }}</code>
              <code>ComputedPage: {{ computedPage }}</code> -->
              <v-pagination
                v-model="table.currentPage"
                :length="table.pages"
                total-visible="12"
                @input="handlePageChange()"
                :disabled="table.tableLoading"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Placeholder de nuevos -->
    <v-row v-if="new_">
      <v-col cols="12" class="d-flex flex-column justify-center align-center">
        <v-img
          src="../ParcelHistory/assets/typography_es.png"
          width="300"
          v-if="!$vuetify.theme.isDark"
        ></v-img>
        <v-img
          src="../ParcelHistory/assets/typography_es_dark.png"
          width="300"
          v-else
        ></v-img>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {
  mdiInformationOutline,
  mdiMagnify,
  mdiCalendar,
  mdiDotsVertical,
  mdiDownload,
} from "@mdi/js";
import axios from "@/plugins/axios";
import { mapActions, mapState } from "vuex";
import DataTable from "./parts/DataTable.vue";
import TripHistoryExportSocketDialog from "@/components/TripHistoryExportSocketDialog.vue";
import generateSocketIdMixin from "@/mixins/generateSocketIdMixin";

export default {
  mixins: [generateSocketIdMixin],
  components: {
    DataTable,
    TripHistoryExportSocketDialog,
  },
  data() {
    return {
      icons: {
        mdiInformationOutline,
        mdiMagnify,
        mdiCalendar,
        mdiDotsVertical,
        mdiDownload,
      },
      date: [],
      menu: false,
      new_: false,
      table: {
        trips: [],
        tableLoading: false,
        headers: [
          { text: "ID VIAJE", value: "unique_id", align: "center" },
          { text: "RESPONSABLE", value: "user_name", align: "start" },
          { text: "CONDUCTOR", value: "provider_name", align: "start" },
          { text: "FECHA Y HORA", value: "date", align: "start", width: 180 },
          { text: "COSTO", value: "total", align: "end" },
          {
            text: "SERVICIO",
            value: "serviceType",
            align: "center",
            sortable: false,
          },
          {
            text: "DIRECCIÓN DE ORIGEN",
            value: "source_address",
            align: "start",
            width: 180,
          },
          {
            text: "DIRECCIÓN DE DESTINO",
            value: "destination_address",
            align: "start",
            width: 180,
          },
          { text: "STATUS", value: "trip_status", align: "center" },
          {
            text: "ACCIÓN",
            value: "actions",
            sortable: false,
            align: "center",
          },
          { text: "", value: "data-table-expand" },
        ],
        currentPage: 1,
        pages: 5,
        searchItem: null,
        limit: this.$vuetify.breakpoint.mdAndUp ? 10 : 5,
        search: "",
        status: this.$t("All"),
      },
      onlyAPIParcel: false,
      payroll: [],
    };
  },
  methods: {
    ...mapActions(["setLoading"]),

    async getHistory() {
      this.table.tableLoading = true;
      let params = {
        page: this.computedPage,
        limit: this.table.limit,
        only_shipments: 1,
        only_api: this.onlyAPIParcel ? 1 : 0,
      };
      if (this.table.search) {
        let search_item = "";
        if (this.table.searchItem === this.$t("unique_id")) {
          search_item = "unique_id";
        }
        if (this.table.searchItem === this.$t("user_first_name")) {
          search_item = "user_first_name";
        }
        if (this.table.searchItem === this.$t("provider_name")) {
          search_item = "provider_first_name";
        }
        if (this.table.search.length >= 1 && this.table.searchItem) {
          params["search_item"] = search_item;
          params["search_value"] =
            this.table.searchItem === "unique_id"
              ? parseFloat(this.table.search)
              : this.table.search;
        }
      }
      if (this.date) {
        if (this.date.length >= 1) {
          let sorted = this.date.sort();
          params["start_date"] = `${sorted[0]}`;
          if (sorted.length === 1) {
            params["end_date"] = `${sorted[0]}`;
          }
          if (sorted.length > 1) {
            params["end_date"] = `${sorted[1]}`;
          }
        }
      }
      switch (this.table.status) {
        case this.$t("All"):
          params["trip_status"] = null;
          break;
        case this.$t("Completed"):
          params["trip_status"] = 1;
          break;
        case this.$t("Cancelled"):
          params["trip_status"] = 2;
          break;
        default:
          break;
      }
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_STAGING}/v2/corporate/trips_history`,
          { params: params }
        );
        if (!!data && data.success) {
          this.table.trips = [];
          data.data.detail.forEach((trip) => {
            trip.is_corporate_user = false;
            if (this.payroll.length && this.payroll.includes(trip.user_id)) {
              trip.is_corporate_user = true;
            }
            this.table.trips.push(trip);
          });
          this.table.pages = data.data.pages;
          this.table.currentPage = parseFloat(data.data.current_page);
          if (data.data.detail.length >= 1) {
            this.new_ = false;
          } else {
            this.new_ = true;
          }
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.table.tableLoading = false;
    },
    handlePageChange() {
      this.getHistory();
    },
    async getHistoryFilter() {
      this.table.currentPage = 1;
      this.table.tableLoading = true;
      let params = {
        page: this.computedPage,
        limit: this.table.limit,
        only_shipments: 1,
        only_api: this.onlyAPIParcel ? 1 : 0,
      };
      if (this.table.search) {
        let search_item = "";
        if (this.table.searchItem === this.$t("unique_id")) {
          search_item = "unique_id";
        }
        if (this.table.searchItem === this.$t("user_first_name")) {
          search_item = "user_first_name";
        }
        if (this.table.searchItem === this.$t("provider_name")) {
          search_item = "provider_first_name";
        }
        if (this.table.search.length >= 1 && this.table.searchItem) {
          params["search_item"] = search_item;
          params["search_value"] =
            this.table.searchItem === "unique_id"
              ? parseFloat(this.table.search)
              : this.table.search;
        }
      }
      if (this.date) {
        if (this.date.length >= 1) {
          let sorted = this.date.sort();
          params["start_date"] = `${sorted[0]}`;
          if (sorted.length === 1) {
            params["end_date"] = `${sorted[0]}`;
          }
          if (sorted.length > 1) {
            params["end_date"] = `${sorted[1]}`;
          }
        }
      }
      switch (this.table.status) {
        case this.$t("All"):
          params["trip_status"] = null;
          break;
        case this.$t("Completed"):
          params["trip_status"] = 1;
          break;
        case this.$t("Cancelled"):
          params["trip_status"] = 2;
          break;
        default:
          break;
      }
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_STAGING}/v2/corporate/trips_history`,
          { params: params }
        );
        if (!!data && data.success) {
          this.table.trips = [];
          data.data.detail.forEach((trip) => {
            trip.is_corporate_user = false;
            if (this.payroll.length && this.payroll.includes(trip.user_id)) {
              trip.is_corporate_user = true;
            }
            this.table.trips.push(trip);
          });
          this.table.pages = data.data.pages;
          this.table.currentPage = parseFloat(data.data.current_page);
          if (data.data.detail.length >= 1) {
            this.new_ = false;
          } else {
            this.new_ = true;
          }
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.table.tableLoading = false;
    },
    async handleExport() {
      try {
        const socketId = this.generateSocketId("providersExport");
        let queryParams = {
          only_shipments: 1,
        };
        if (this.table.search) {
          let search_item = "";
          if (this.table.searchItem === this.$t("unique_id")) {
            search_item = "unique_id";
          }
          if (this.table.searchItem === this.$t("user_first_name")) {
            search_item = "user_detail.first_name";
          }
          if (this.table.searchItem === this.$t("provider_name")) {
            search_item = "provider_detail.first_name";
          }
          if (this.table.search.length >= 1 && this.table.searchItem) {
            queryParams["search_item"] = search_item;
            queryParams["search_value"] =
              this.table.searchItem === "unique_id"
                ? parseFloat(this.table.search)
                : this.table.search;
          }
        }
        if (this.date) {
          if (this.date.length >= 1) {
            let sorted = this.date.sort();
            queryParams["start_date"] = `${sorted[0]}`;
            if (sorted.length === 1) {
              queryParams["end_date"] = `${sorted[0]}`;
            }
            if (sorted.length > 1) {
              queryParams["end_date"] = `${sorted[1]}`;
            }
          }
        }
        switch (this.table.status) {
          case this.$t("All"):
            queryParams["trip_status"] = null;
            break;
          case this.$t("Completed"):
            queryParams["trip_status"] = 1;
            break;
          case this.$t("Cancelled"):
            queryParams["trip_status"] = 2;
            break;
          default:
            break;
        }
        queryParams.socketId = socketId;

        axios
          .get(
            `${process.env.VUE_APP_STAGING}/v2/corporate/trips_history/export`,
            {
              params: queryParams,
              timeout: 1800000, // 30 minutes in milliseconds
            }
          )
          .then((response) => {
            console.log(
              "🚩🚩🚩- DM ~ ./V2/corporate/trips_history/export ~ response:",
              response
            );
          })
          .catch((error) => {
            console.log("🚩🚩🚩- DM ~ handleExport ~ error:", error);
            this.$dialog.notify.error(error?.message || "Ha ocurrido un error");
            throw new Error("Error en request");
          });

        const payload = {
          socketId,
        };

        const params = {
          fullscreen: !this.$vuetify.breakpoint.lgAndUp,
          width: "40%",
          height: "100%",
          transition: "dialog-top-transition",
          payload: payload,
          scrollable: true,
          showClose: false,
          dialogTitle: this.$t("Exporting parcels"),
          processingMessage: this.$t("Processing page"),
          ofMessage: this.$t("of"),
          downloadMessage: this.$t("Download"),
          goBackMessage: this.$t("GoBack"),
          expiredMessage: this.$t("expired"),
          youCanDownloadMessage: this.$t("You can download the export file"),
          exportFileHasExpiredMessage: this.$t("The export file has"),
          processingPageMessage: this.$t("Processing pages for Excel file"),
          errorMessage: this.$t("Sorry, there was an error"),
          closeMessage: this.$t("Close"),
        };

        const dialogPromise = await this.$dialog.showAndWait(
          TripHistoryExportSocketDialog,
          params
        );

        if (dialogPromise === "closed") {
          console.log("closed");
        }
      } catch (error) {
        console.log("🚩🚩🚩- DM ~ handleExport ~ error:", error);
        this.$dialog.notify.error(error?.message || "Ha ocurrido un error");
      } finally {
        this.loadingExcel = false;
      }
    },
    getPayRoll() {
      axios
        .get("all_users")
        .then((response) => {
          if (response.data) {
            this.payroll = response.data.users.map((user) => user._id);
          }
        })
        .catch((error) => {
          console.log("🚩🚩🚩- DM ~ getPayRoll ~ error:", error);
        });
    },
    validateCorporateUsers() {
      this.table.trips.forEach((trip) => {
        trip.is_corporate_user = this.payroll.includes(trip.user_id);
      });
    },
  },
  computed: {
    ...mapState("auth", [
      "is_corporate_api_partner",
      "is_allowed_corporate_shipment_api",
    ]),
    computedPage() {
      return this.table.currentPage;
    },
    computedTable() {
      return this.table.trips;
    },
    computedMaxDate() {
      let today = new Date();
      // utc-4 ccs
      return this.$moment(today).add(-4, "hours").toISOString();
    },
  },
  created() {
    this.getPayRoll();
    this.getHistory();
  },
  watch: {
    "table.limit": function (oldVal, newVal) {
      if (oldVal > newVal) {
        this.table.currentPage = 1;
      }
      this.getHistory();
    },
    "table.currentPage": function (newVal, oldVal) {
      if (!newVal) {
        this.table.currentPage = oldVal;
      }
    },
    date: function () {
      if (this.date && this.date.length === 2) {
        const startDate = this.$moment(this.date[0]);
        const endDate = this.$moment(this.date[1]);

        if (!endDate.isSameOrBefore(startDate.clone().add(1, "month"))) {
          this.$dialog.notify.error(
            "Por favor escoger un rango menor a un mes"
          );
          this.date = null;
        }
      }
    },
    payroll() {
      this.validateCorporateUsers();
    },
  },
};
</script>
<style lang="scss" scoped>
.cursor-hover:hover {
  cursor: pointer;
}
</style>
